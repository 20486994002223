import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss'

import 'vue3-carousel/dist/carousel.css'
import './assets/scss/marque-slider.css'

import Vue3Lottie from 'vue3-lottie'

import axios from 'axios'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

createApp(App)
    .use(router)
    .use(Vue3Lottie)
    .mount('#app')