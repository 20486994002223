<template>
  <section class="sec-what-next">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-12 align-self-center">
          <div class="form-wrapper">
            <h2 class="h2">Tailored UX design services <br>
              for your business</h2>
            <p class="large">Fill in this form or <a href="mailto:demo@gmail.com">send us an e-mail</a></p>
            <div class="form">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name*">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Phone number*">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email*">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Description*">
                  </div>
                </div>
                <div>
                  <p id="contact-message">
                  </p>
                </div>
                <div class="col-md-12">
                  <div class="form-group submit">
                    <button type="button" class="btn-style no-hover" @click="submitForm('contact-message')">Contact Us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 align-self-center">
          <div class="what-next-wrapper">
            <h3 class="h3 white">What’s Next?</h3>
            <ul class="next">
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/what-next-icon-1.svg" alt="icon">
                </div>
                <div class="content">
                  <p class="large white">Our experts will analyze your
                    requirements and contact you within 1-2 business days.</p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/what-next-icon-2.svg" alt="icon">
                </div>
                <div class="content">
                  <p class="large white">Our team will collect all requirements for
                    your project, and if needed, we will sign NDA to ensure that highest
                    level of privacy.</p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/what-next-icon-3.svg" alt="icon">
                </div>
                <div class="content">
                  <p class="large white">We will develop a comprehensive proposal and
                    an action plan for your project with estimates, timelines, CVs, etc.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 order-lg-1 order-2">
          <div class="logo-wrapper">
            <a href="#" class="logo">
              <img src="../assets/images/footer-logo.svg" alt="logo">
            </a>
            <ul class="contact">
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/whatsapp-black-icon.svg" alt="icon">
                </div>
                <a href="tel:(017) 8885055">(017) 8885055</a>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/mail-black-icon.svg" alt="icon">
                </div>
                <a href="mailto:hello@flowdigital.my">hello@flowdigital.my</a>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/address-black-icon.svg" alt="icon">
                </div>
                <p>28-3 Jalan PJU 5/4, Dataran Sunway,
                  Kota Damansara, Petaling Jaya, Selangor, Malaysia.</p>
              </li>
            </ul>
            <div class="rate-us">
              <p class="large black bold">Reach Us</p>
              <ul class="social">
                <li><a href="#" target="_blank"><img src="../assets/images/icons/linkedin-icon.svg" alt="icon"></a></li>
                <li><a href="#" target="_blank"><img src="../assets/images/icons/be-icon.svg" alt="icon"></a></li>
                <li><a href="#" target="_blank"><img src="../assets/images/icons/instgram-icon.svg" alt="icon"></a></li>
                <li><a href="#" target="_blank"><img src="../assets/images/icons/dribble-icon.svg" alt="icon"></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12 order-lg-2 order-1">
          <div class="link-main-wrapper">
            <div class="link-wrapper" v-for="menu in footerMenus" :key="menu.id">
              <p class="large black bold">{{ menu.name }} <img src="../assets/images/icons/arrow-right-black-icon.svg" class="icon" alt="icon"></p>
              <ul>
                <li v-for="subMenu in menu.children" :key="subMenu.id"><a :href="subMenu.link">{{ subMenu.name }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="bottom-footer">
    <div class="container">
      <p>© Copyright 2024, Flow Digital. All rights reserved.</p>
    </div>
  </div>
  
  <!-- Project Modal -->
  <div class="modal fade" id="start-project-modal" tabindex="-1" aria-labelledby="start-project-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <a href="#" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
          <img src="../assets/images/icons/close.svg" alt="close-icon">
        </a>
        <div class="modal-body">
          <div class="form-wrapper">
            <h3>Start a Project</h3>
            <div class="form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Name <span>*</span></label>
                    <input type="text" id="name" class="form-control" placeholder="Enter your name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Company Name <span>*</span></label>
                    <input type="text" id="company-name" class="form-control" placeholder="Company Name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Email <span>*</span></label>
                    <input type="email" id="email" class="form-control" placeholder="Enter your email">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Contact Number <span>*</span></label>
                    <input type="tel" id="contact-number" class="form-control" placeholder="Enter your contact number">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Project Description</label>
                    <textarea id="message" class="form-control" placeholder="Project Descriptions ..."></textarea>
                  </div>
                </div>
                <div>
                  <p id="response-message">
                  </p>
                </div>
                <div class="col-md-12">
                  <div class="form-group submit">
                    <a href="#" class="btn-style blue" @click="submitForm('response-message')">Submit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';

const footerMenus = ref([]);


const fetchFooterMenu = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/fetch-footer-menu`);
    footerMenus.value = response.data.data;
  } catch (error) {
    console.error('Error fetching footer menu:', error);
  }
};

const submitForm = async (messageId) => {
  document.getElementById(messageId).innerHTML = '';
  document.getElementById(messageId).style.display = 'none';

  const contactModal = document.getElementById('start-project-modal');

  contactModal.addEventListener('hide.bs.modal', () => {
    document.getElementById(messageId).innerHTML = '';
    document.getElementById(messageId).style.display = 'none';
    
    document.getElementById('name').value = '';
    document.getElementById('email').value = '';
    document.getElementById('company-name').value = '';
    document.getElementById('contact-number').value = '';
    document.getElementById('message').value = '';
  });

  const formData = new FormData();
  let name = document.getElementById('name').value;
  let email = document.getElementById('email').value;
  let companyName = document.getElementById('company-name').value;
  let contactNumber = document.getElementById('contact-number').value;
  let message = document.getElementById('message').value;

  if(name === '' || email === '' || companyName === '' || contactNumber === '' || message === '') {
    document.getElementById(messageId).innerHTML = 'Please fill in all the required fields';
    document.getElementById(messageId).style.color = 'red';
    document.getElementById(messageId).style.display = 'block';
    return;
  }

  formData.append('name', name);
  formData.append('email', email);
  formData.append('company', companyName);
  formData.append('phone', contactNumber);
  formData.append('message', message);
  
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/form`, formData);
    
    if(response.data.status === 'success') {
      document.getElementById(messageId).innerHTML = 'Form submitted successfully, We will contact you soon';
      document.getElementById(messageId).style.color = 'green';
      document.getElementById(messageId).style.display = 'block';
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    document.getElementById(messageId).innerHTML = error.response.data.message;
    document.getElementById(messageId).style.color = 'red';
    document.getElementById(messageId).style.display = 'block';
  }
};

onMounted(() => {
  fetchFooterMenu();
});
</script>

<style scoped>
#response-message {
  display: none;
  margin-bottom: 10px;
}
</style>

