<template>
  <div class="home">
    <section class="sec-banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 align-self-center">
            <div class="content-wrapper">
              <img src="../assets/images/icons/banner-star-icon.svg" class="star-icon" alt="icon">
              <img src="../assets/images/icons/banner-check-icon.svg" class="check-icon" alt="icon">
              <h1>
                <strong>We Build.</strong> <br>
                <span class="animation-wrapper">
                  <span v-for="(item, index) in bannerHeading" :key="index" class="brand-animation"
                        :class="{ 'active': index === activeIndex }">{{ item.name }}</span>
                </span>
              </h1>
              <p class="large blue">Digital Solutions are all about great experiences. <br>
                We take your ideas and shape them into a journey your
                users <br> will keep coming back to.</p>
              <a href="#" class="btn-style btn-shadow" data-bs-toggle="modal" data-bs-target="#start-project-modal">Start A Project</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 align-self-center">
            <div class="expert-wrapper">
              <div class="img-wrapper">
                <img src="../assets/images/banner-img.png" alt="img">
              </div>
              <div class="talk-expert">
                <p class="large">Got Questions?</p>
                <p class="large bold">Talk to our experts !</p>
                <p>Our team typically replies in a few hours.</p>
                <a href="#" class="btn-style blue" data-bs-toggle="modal" data-bs-target="#start-project-modal">Send Message</a>
                <img src="../assets/images/icons/cursor-icon.svg" class="cursor-icon" alt="icon">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-categories">
      <div class="container">
        <div class="categories-wrapper">
          <div class="categories-card">
            <p class="small">Our Work</p>
            <div class="icon">
              <img src="../assets/images/icons/our-work-icon.svg" alt="icon">
            </div>
          </div>
          <div class="categories-card">
            <p class="small">Customers</p>
            <div class="icon">
              <img src="../assets/images/icons/customer-icon.svg" alt="icon">
            </div>
          </div>
          <div class="categories-card">
            <p class="small">Freelancers</p>
            <div class="icon">
              <img src="../assets/images/icons/freelancer-icon.svg" alt="icon">
            </div>
          </div>

          <div class="categories-card">
            <p class="small">Flowspace</p>
            <div class="icon">
              <img src="../assets/images/icons/flow-space-icon.svg" alt="icon">
            </div>
          </div>
          <div class="categories-card">
            <p class="small">Blog</p>
            <div class="icon">
              <img src="../assets/images/icons/blog-icon.svg" alt="icon">
            </div>
          </div>
          <div class="categories-card">
            <p class="small">Career</p>
            <div class="icon">
              <img src="../assets/images/icons/case-icon.svg" alt="icon">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-trusted-brand">
      <div class="container">
        <h3>We make meeting global leaders easier.</h3>
      </div>
      <vue-marquee-slider
          id="marquee-slider"
          :speed="12000"
          :autoWidth="true"
          :width="32"
          :space="32"
      >
        <a href="#" class="brand-card" v-for="item in trustedBrands" :key="item.id">
          <img :src="item.img" alt="img">
        </a>
      </vue-marquee-slider>
    </section>

    <section class="sec-solution-partner">
      <div class="container">
        <div class="solution-partner-wrapper">
          <div class="line-icon">
            <img src="../assets/images/icons/solution-partner-icon.svg" alt="icon">
          </div>
          <h2 class="h2 medium">Your 360-Degree Web Solutions Partner</h2>
          <h3>Highlight the growth of your company by the numbers</h3>
          <p class="p blue">From product ideation to development, our
            talented team helps bring your products to market. <br>
            Engage in 360-degree services through our extensive network.</p>
          <div class="solution-card-wrapper">
            <div class="solution-card">
              <div class="icon-wrapper">
                <img src="../assets/images/icons/solution-partner-service-icon.svg" alt="icon">
              </div>
              <div class="counter-wrapper">
                <strong>25</strong>
                <div class="content">
                  <h3 class="white">Services</h3>
                  <p class="white small">in our network.</p>
                </div>
              </div>
            </div>
            <div class="solution-card">
              <div class="icon-wrapper">
                <img src="../assets/images/icons/solution-partner-heart-icon.svg" alt="icon">
              </div>
              <div class="counter-wrapper">
                <strong>70%</strong>
                <div class="content">
                  <h3 class="white">Customers</h3>
                  <p class="white small">Stay with us after first project</p>
                </div>
              </div>
            </div>
            <div class="solution-card">
              <div class="content">
                <h3 class="white">The Web Agency</h3>
                <p class="white small">Driving Growth with <br>
                  Comprehensive Web Solutions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-on-demand">
      <div class="container">
        <h2>On-Demand Service <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a></h2>
        <p class="blue">Tailored Solutions for Your Convenience and Satisfaction</p>
        <div class="row">
          <ServiceCard/>
        </div>
        <div class="btn-wrapper">
          <RouterLink to="/service" class="btn-style transparent">Learn More</RouterLink>
        </div>
      </div>
    </section>

    <section class="sec-our-clients">
      <div class="container">
        <h2>Our Clients Who Chose Flow Digital <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a></h2>
        <p class="blue">It’s always the best source to
          hear from others who have already worked with us. </p>
        <carousel :breakpoints="clientsBreakpoints">
          <slide v-for="item in ourClients" :key="item.id">
            <div class="clients-card">
              <div class="img-wrapper">
                <img :src="item.brand_image" class="brand-img" alt="img">
                <img :src="item.url" class="client-img" alt="img">
              </div>
              <div class="content-wrapper">
                <h3>{{ item.name }}</h3>
                <p class="small medium">{{ item.position }}</p>
              </div>
            </div>
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
    </section>

    <section class="sec-top-ventures">
      <div class="container">
        <h2>We Are United With Top Ventures <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a></h2>
        <p class="blue">Tailored Solutions for Your Convenience and Satisfaction</p>
        <ul class="brand">
          <li v-for="(img, index) in images" :key="index">
            <a href="#"><img :src="require(`@/assets/images/${img}`)" alt="img"></a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script setup>
import { VueMarqueeSlider } from 'vue3-marquee-slider';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import ServiceCard from "@/components/ServiceCard.vue";
import { ref, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';

const activeIndex = ref(null);
const intervalId = ref(null);

const bannerHeading = ref([
  {name: 'All-In-One Solutions.'},
  {name: 'Immersive Experiences.'},
  {name: 'Transformative Platforms.'},
  {name: 'Everything you need.'},
]);

const images = ref([
  'top-ventures-img-2.svg',
  'top-ventures-img-1.svg',
  'top-ventures-img-3.svg',
  'top-ventures-img-5.svg',
  'top-ventures-img-4.svg',
  'top-ventures-img-11.svg',
  'top-ventures-img-6.svg',
  'top-ventures-img-7.svg',
  'top-ventures-img-8.svg',
  'top-ventures-img-9.svg',
  'top-ventures-img-12.svg',
  'top-ventures-img-13.svg',
  'top-ventures-img-10.svg',
  'top-ventures-img-14.svg'
]);

const trustedBrands = ref([
  {img: require('@/assets/images/trusted-brand-img-1.svg')},
  {img: require('@/assets/images/trusted-brand-img-2.svg')},
  {img: require('@/assets/images/trusted-brand-img-3.svg')},
  {img: require('@/assets/images/trusted-brand-img-4.svg')},
  {img: require('@/assets/images/trusted-brand-img-5.svg')},
  {img: require('@/assets/images/trusted-brand-img-6.svg')},
  {img: require('@/assets/images/trusted-brand-img-7.svg')},
]);

const startTimer = () => {
  activeIndex.value = 0;

  intervalId.value = setInterval(() => {
    activeIndex.value++;

    if (activeIndex.value >= bannerHeading.value.length) {
        activeIndex.value = 0; // Reset to the beginning when reaching the end
      }
  }, 3000); // 3 seconds interval
};

const ourClients = ref([]);

const fetchTestimonials = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/testimonial-list`);
    ourClients.value = response.data;
  } catch (error) {
    console.log('Error fetching testimonials:', error);
  }
};

const clientsBreakpoints = ref({
  300: {
    itemsToShow: 1.7,
  },
  768: {
    itemsToShow: 2.8,
  },
  1200: {
    itemsToShow: 3.8,
  }
});

onMounted(() => {
  startTimer();
  fetchTestimonials();
});

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
});

</script>
