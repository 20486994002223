<template>
  <header :class="$route.path === '/' ? 'light-shadow' : ''">
    <div class="header-layer" :class="{'active' : isMenuOpen}" @click="isMenuOpen = false; activeMenu = '';"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-8 align-self-center">
          <div class="header-wrapper">
            <RouterLink :to="{ name: 'home' }" class="logo" @click.prevent="toggleMenu('')">
              <img src="../assets/images/logo.svg" alt="Logo">
            </RouterLink>
            <div class="navbar-menu" :class="{'active' : isMenuOpen}">
              <a href="#" class="logo">
                <img src="../assets/images/logo.svg" alt="Logo">
              </a>
              <ul class="menu">
                <template v-for="menu in menus" :key="menu.id">
                  <li :class="{ 'active': activeMenu === menu.name }" v-if="menu.link === '#'">
                    <a href="#" @click.prevent="toggleMenu(menu.name)">{{ menu.name }}</a>
                    <ul class="submenu">
                      <li v-for="subMenu in menu.children" :key="subMenu.id">
                        <RouterLink :to="subMenu.link" @click.prevent="toggleMenu('')">{{ subMenu.name }}</RouterLink>
                      </li>
                    </ul>
                  </li>

                  <li class="no-arrow" v-else><RouterLink :to="menu.link" @click.prevent="toggleMenu('')">{{ menu.name }}</RouterLink></li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-4 align-self-center">
          <div class="hamburger-menu">
            <a href="#" @click="isMenuOpen = true;"><img src="../assets/images/icons/hamburger-icon.svg" alt="icon"></a>
          </div>
          <div class="btn-wrapper">
            <a href="#" class="btn-style btn-shadow" data-bs-toggle="modal" data-bs-target="#start-project-modal">Start A Project</a>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="mega-menu" :class="{ 'active': activeMenu}">
    <div class="mega-menu-overlay" @click="activeMenu = '';"></div>
    <div class="container">
      <div class="mega-menu-main">
        <template v-for="menu in menus" :key="menu.id">
          <div :class="['mega-menu-wrapper', { 'active': activeMenu === menu.name, 'about-mega-menu': menu.name === 'About', 'customers-mega-menu': menu.name === 'Customer', 'service-mega-menu': menu.name === 'Service', 'technologies-mega-menu': menu.name === 'Technology' }]">
            <div class="mega-column-wrapper" v-if="menu.name === 'About'">
              <div class="mega-column-card">
                <RouterLink :to="{ name: 'company' }" class="column-card mb-12" @click.prevent="toggleMenu('')">
                  <div class="column-header">
                    <div class="title-wrapper">
                      <div class="icon">
                        <img src="../assets/images/icons/compnay-icon.svg" alt="icon">
                      </div>
                      <p class="large bold black">Company</p>
                    </div>
                    <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                  </div>
                  <div class="column-body">
                    <p class="small">80% of our clients continue their services
                      with us after one project, highlighting their
                      satisfaction and our excellence.</p>
                  </div>
                </RouterLink>
                <RouterLink :to="{ name: 'life-at-flow' }" class="column-card" @click.prevent="toggleMenu('')">
                  <div class="column-header">
                    <div class="title-wrapper">
                      <div class="icon">
                        <img src="../assets/images/icons/life-flow-icon.svg" alt="icon">
                      </div>
                      <p class="large bold black">Life at Flow</p>
                    </div>
                    <div  class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                  </div>
                  <div class="column-body">
                    <p class="small">At Flow, our dynamic and supportive environment fosters
                      creativity and collaboration, ensuring our team thrives
                      both professionally and personally.</p>
                  </div>
                </RouterLink>
              </div>
              <div class="mega-column-card">
                <a href="#" class="column-card h-100 pb-150" @click.prevent="toggleMenu('')">
                  <div class="column-header">
                    <div class="title-wrapper">
                      <div class="icon">
                        <img src="../assets/images/icons/career-icon.svg" alt="icon">
                      </div>
                      <p class="large bold black">Career</p>
                    </div>
                    <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                  </div>
                  <div class="column-body">
                    <p class="small">Join Flow to advance your career with exciting opportunities for growth, continuous learning, and impactful work that makes a difference.</p>
                    <div class="career-img">
                      <img src="../assets/images/icons/popup-career-img.png" alt="img">
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="mega-column-wrapper" v-if="menu.name === 'Customers'">
              <div class="mega-column-card">
                <RouterLink :to="{ name: 'customer' }" class="column-card mb-12" @click.prevent="toggleMenu('')">
                  <div class="column-header">
                    <div class="title-wrapper">
                      <div class="icon">
                        <img src="../assets/images/icons/compnay-icon.svg" alt="icon">
                      </div>
                      <p class="large bold black">Our Customers</p>
                    </div>
                    <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                  </div>
                  <div class="column-body">
                    <p class="small">We build strong, lasting relationships by
                      delivering exceptional service and tailored
                      solutions that meet their unique needs.</p>
                  </div>
                </RouterLink>
                <RouterLink :to="{ name: 'case-study' }" class="column-card" @click.prevent="toggleMenu('')">
                  <div class="column-header">
                    <div class="title-wrapper">
                      <div class="icon">
                        <img src="../assets/images/icons/life-flow-icon.svg" alt="icon">
                      </div>
                      <p class="large bold black">Case Studies</p>
                    </div>
                    <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                  </div>
                  <div class="column-body">
                    <p class="small">Real-world success stories highlighting
                      clients' achievements with our innovative
                      solutions and dedicated support.</p>
                  </div>
                </RouterLink>
              </div>
              <div class="mega-column-card">
                <RouterLink :to="{ name: 'customer' }" class="column-card" @click.prevent="toggleMenu('')">
                  <div class="column-header">
                    <div class="title-wrapper">
                      <p class="large bold black">Customers <br>
                        Testimonials</p>
                    </div>
                    <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                  </div>
                  <div class="column-body">
                    <p class="small">Hear directly from our clients about their positive
                      experiences and the impactful results they've achieved
                      through our services.</p>
                  </div>
                </RouterLink>
                <div class="chat-card">
                  <div class="icon">
                    <img src="../assets/images/icons/chat-green-icon.svg" alt="icon">
                  </div>
                  <div class="content">
                    <p class="small">We can collaborate and develop
                      on-demand software or tools as
                      per your needs.</p>
                    <a href="#" class="link small" data-bs-toggle="modal" data-bs-target="#start-project-modal">Start a Project <img src="../assets/images/icons/arrow-right-blue-icon.svg" alt="icon"></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="column-tab-wrapper" v-if="menu.name === 'Service'">
              <div class="column-tab-card">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation" v-for="(service, index) in getSubServices('Service')" :key="service.id">
                    <button class="nav-link" :class="{'active': activeServiceTab === 'tab_'+index}" @click="activeServiceTab = 'tab_'+index">
                      <span class="name-wrapper">
                        <img :src="service.image" alt="" class="icon">
                        {{ service.name }}
                      </span>
                      <RouterLink :to="`${service.link}`" class="link" @click.prevent="toggleMenu('')"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                    </button>
                  </li>
                </ul>
              </div>
              <div class="column-tab-card">
                <div class="tab-content" id="myTabContent" v-for="(service, index) in getSubServices('Service')" :key="service.id">
                  <div class="tab-pane fade" :class="{'show active': activeServiceTab === 'tab_'+index}">
                    <div class="service-detail-card">
                      <p class="large title">{{ service.name }}</p>
                      <ul class="service">
                        <li v-for="subService in service.children" :key="subService.id">
                          <RouterLink :to="subService.link" v-if="subService.type === 'custom'" @click.prevent="toggleMenu('')">{{ subService.name }}</RouterLink>
                          <RouterLink :to="`${subService.link}`" v-else @click.prevent="toggleMenu('')">{{ subService.name }}</RouterLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column-tab-wrapper technology-tab" v-if="menu.name === 'Technology'">
              <div class="column-tab-card">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <template v-for="(technology, index) in getSubServices('Technology')" :key="technology.id">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" :class="{'active': activeTechnologyTab === 'tab_'+index}" @click="activeTechnologyTab = 'tab_'+index">
                        <span class="name-wrapper">
                          <img :src="technology.image" alt="" class="icon">
                          {{ technology.name }}
                        </span>
                        <RouterLink to="/service" class="link" @click.prevent="toggleMenu('')"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                      </button>
                    </li>
                  </template>
                </ul>
              </div>
              <div class="column-tab-card">
                <div class="tab-content" id="myTabContent" v-for="(technology, index) in getSubServices('Technology')" :key="technology.id">
                  <div class="tab-pane fade" :class="{'show active': activeTechnologyTab === 'tab_'+index}">
                    <div class="service-detail-card technology-detail-card">
                      <p class="large title">{{ technology.name }}</p>
                      <ul class="service">
                        <li v-for="subService in technology.children" :key="subService.id">
                          <RouterLink :to="subService.link" v-if="subService.type === 'custom'" @click.prevent="toggleMenu('')">{{ subService.name }}</RouterLink>
                          <RouterLink :to="`${subService.link}`" v-else @click.prevent="toggleMenu('')">{{ subService.name }}</RouterLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import { ref, onBeforeMount } from 'vue';

const activeMenu = ref(null);
const isMenuOpen = ref(false);
const menus = ref([]);

const toggleMenu = (menu) => {
  activeMenu.value = activeMenu.value === menu ? '' : menu;
}

const activeServiceTab = ref('tab_0');
const activeTechnologyTab = ref('tab_0');

const getSubServices = (categoryName) => {
  return menus.value.filter(menu => menu.name === categoryName)[0].children
}

const fetchMenu = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/fetch-menu`);
    menus.value = response.data.data;
  } catch (error) {
    console.error('Error fetching menu:', error);
  }
};

onBeforeMount(() => {
  fetchMenu();
});
</script>

<style scoped>
.service-detail-card {
  & .service li {
    display: block;
  }
}
</style>