<template>
  <div class="col-md-3 col-4" v-for="(card, index) in serviceCards" :key="index">
    <div class="demand-card">
      <div class="img-wrapper" :style="{ backgroundColor: card.color }">
        <img :src="card.imgSrc" alt="img">
        <ul class="categories">
          <li v-for="(feature, index) in card.features" :key="index">
            <template v-if="index < 5">
              <a :href="feature.url">{{ feature.title }}</a>
            </template>
          </li>
        </ul>
      </div>
      <div class="content-wrapper">
        <p class="p bold">{{ card.title }}</p>
        <ul class="feature">
          <li v-for="(feature, index) in card.features" :key="index">
            <a :href="feature.url">{{ feature.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import { ref, onMounted } from 'vue';

const services = ref([]);
const serviceCards = ref([
        {
          title: 'Website Development',
          imgSrc: require('@/assets/images/demand-card-img-1.png'),
          features: [],
          color: '#6274ed'
        },
        {
          title: 'UI/UX Design',
          imgSrc: require('@/assets/images/demand-card-img-2.png'),
          features: [],
          color: '#1b2234'
        },
        {
          title: 'Mobile App Development',
          imgSrc: require('@/assets/images/demand-card-img-3.png'),
          features: [],
          color: '#ffc301'
        },
        {
          title: 'Ecommerce Development',
          imgSrc: require('@/assets/images/demand-card-img-4.png'),
          features: [],
          color: '#fad3e3'
        },
        {
          title: 'Custom Service Development',
          imgSrc: require('@/assets/images/demand-card-img-5.png'),
          features: [],
          color: '#3467ff'
        },
        {
          title: 'Digital Marketing',
          imgSrc: require('@/assets/images/demand-card-img-6.png'),
          features: [],
          color: '#060f17'
        },
        {
          title: 'Social Media Marketing',
          imgSrc: require('@/assets/images/demand-card-img-7.png'),
          features: [],
          color: '#6274ed'
        },
        {
          title: 'Corporate Branding',
          imgSrc: require('@/assets/images/demand-card-img-8.png'),
          features: [],
          color: '#ff4f28'
        }
      ]);

const fetchService = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/service-list`);
    services.value = response.data.data;

    serviceCards.value = serviceCards.value.map(card => {
      const matchedServices = services.value.filter(service => service.name === card.title);
      if (matchedServices.length > 0) {
        matchedServices.forEach(service => {
          service.sub_services.forEach(subService => {
            card.features.push({
              title: subService.name,
              url: `/service/${subService.slug}`
            });
          });
        });
      }
    
      return card;
    });
  } catch (error) {
    console.error('Error fetching services:', error);
  }
}

onMounted(() => {
  fetchService();
});
</script>