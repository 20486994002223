import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Home', transition: 'fade' },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: 'About', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: { title: 'Service', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "service" */ '../views/ServiceView.vue')
  },
  {
    path: '/company',
    name: 'company',
    meta: { title: 'Company', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/CompanyView.vue')
  },
  {
    path: '/life-at-flow',
    name: 'life-at-flow',
    meta: { title: 'Life At Flow', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/LifeFlow.vue')
  },
  {
    path: '/customer',
    name: 'customer',
    meta: { title: 'Customer', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/CustomerView.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    meta: { title: 'Portfolio', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/PortfolioView.vue')
  },
  {
    path: '/case-study',
    name: 'case-study',
    meta: { title: 'Case Study', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/CasestudyView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    meta: { title: 'Blog', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/BlogView.vue')
  },
  {
    path: '/blog/:id',
    name: 'blog-single',
    meta: { title: 'Blog Single', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/BlogSingle.vue')
  },
  {
    path: '/case-study/:id',
    name: 'project',
    meta: { title: 'Project', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/ProjectView.vue')
  },
  {
    path: '/service/:id',
    name: 'service-detail',
    meta: { title: 'Service Detail', transition: 'fade' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/ServiceDetail.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error-404',
    meta: { title: 'Error 404', transition: 'fade' },
    component: () => import(/* webpackChunkName: "company" */ '../views/ErrorNotFound.vue')
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    meta: { title: 'Coming Soon', transition: 'fade' },
    component: () => import(/* webpackChunkName: "company" */ '../views/ComingSoon.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

router.afterEach((to) => {
  document.title = 'Flow Digital - ' + to.meta.title || 'Flow Digital'
})

export default router
